
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';


@Component
export default class RelatorioReceita extends PageBase { 
service = new RelatorioService();
item = new RelatorioModel();
overlay: boolean = false;
filter:any = {};

mounted() {
    this.filter = this.$route.query;
    this.overlay = true;

    this.service.ListarRelatorioVendas(this.filter.empreendimentoIds, this.filter.empresaIds, this.filter.dataContratoInicial, this.filter.dataContratoFinal, this.filter.numeroContrato, this.filter.clienteId)
        .then(
            res => {
                this.item = res.data;
            },  
            err=> {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            } 
        ).finally(() =>{
            this.overlay = false;
        }); 
    }
} 

class RelatorioModel {
    public empreendimentoNome:string = "";
    public grupoNome:string = "";
    public unidadeNome:string = "";
    public clienteNome:string = "";
    public clienteCEP:string = "";
    public clienteLogradouro:string = "";
    public clienteNumero:string = "";
    public clienteComplemento:string  = "";
    public clienteBairro:string = "";
    public clienteMunicipio:string = "";
    public clienteUF:string = "";
    public clienteEmail:string = "";
    public clienteCelular:string = "";
    public numeroSequenciaContrato:string = "";
    public situacaoContrato:string = "";
    public dataContrato:string = "";
    public dataUltimoPagamento:string = "";
    public valorTotalPago:number = 0;
    public valorTotalAberto:number = 0;
}   

